/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// styles
import { magnifyStyle } from '@styles/global';

// components
import Layout from '@components/Layout';

// views
import HeroSection from '@views/JobPageView/HeroSection';
import IntroSection from '@views/JobPageView/IntroSection';
import SEO from '@src/components/SEO';
import JobsListSection from '@src/views/JobPageView/JobsListSection/JobsListSection';

const EmploisPage = ({ data }) => {
  const {
    jobs: { nodes: jobs = [] },
  } = data;

  const langLinks = [{ langKey: 'en', href: '/en/jobs' }];

  const reducedJobs = jobs.map((job) => ({
    title: job.title,
    description: job._rawDescription,
    responsibilities: job.responsibilities,
    requirements: job.requirements,
    qualifications: job.qualifications,
  }));

  return (
    <Layout localeLinks={langLinks}>
      <SEO langLinks={langLinks} title='Emplois' description='' />

      <HeroSection>
        <h1>Opportunités d’emplois</h1>
      </HeroSection>

      <IntroSection>
        <p css={magnifyStyle}>
          Joignez les rangs d’une équipe et d’une entreprise solides!
        </p>

        <p>
          Situé à Sainte-Marguerite, Permafil est une entreprise manufacturière
          fabriquant des produits de rangements métalliques principalement
          dédiés au secteur de la grande construction et du commerce de détail.
          En plus de fabriquer également une gamme de produits dédiés aux
          services d’urgences, Permafil conçoit et fabrique des présentoirs
          commerciaux pour le commerce de détail.
        </p>
      </IntroSection>

      <JobsListSection jobs={reducedJobs} />
    </Layout>
  );
};

EmploisPage.propTypes = {
  data: PropTypes.shape({
    jobs: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default EmploisPage;

export const query = graphql`
  query EmploisPageQuery {
    jobs: allSanityJob(filter: { localization: { locale: { eq: "fr" } } }) {
      nodes {
        title
        responsibilities
        requirements
        qualifications
        _rawDescription(resolveReferences: { maxDepth: 10 })
        localization {
          locale
        }
        id
      }
    }
  }
`;
